/* Common Imports */

import React from "react";

/* Redux Imports */

// import StateInterface from "../../../redux-magic/state-interface";

/* Component Imports */

import { Fab, styled, Tooltip, useTheme } from "@mui/material";
// import FeedBackModal from "../../modals/FeedbackModal";
import ShareModal from "../../modals/ShareModal";

/* Icon Imports */

import { KeyboardArrowUp, Share, BugReport } from "@mui/icons-material";

/* Styled Components */

const Container = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-end",
	alignItems: "flex-start",
	position: "fixed",
	bottom: theme.spacing(2),
	right: theme.spacing(2),
	zIndex: 5,
	[theme.breakpoints.down("md")]: {
		bottom: theme.spacing(9),
	},
}));

const CustomFab = styled(Fab)(({ theme }) => ({
	background: theme.palette.primary.main,
	color: theme.palette.primary.contrastText,
	boxShadow: "none",
	"&:hover": {
		background: "#CA6614",
	},
}));

const Fabs = ({
	session,
	dispatch,
	enableShare,
	shareUrl,
}: {
	session: any;
	dispatch: Function;
	enableShare?: boolean;
	shareUrl?: string;
}) => {
	const theme = useTheme();

	const [visible, setVisible] = React.useState(false);
	const [shareModal, setShareModal] = React.useState(false);

	const toggleVisible = () => {
		const scrolled = document.documentElement.scrollTop;
		if (scrolled > 300) {
			setVisible(true);
		} else if (scrolled <= 300) {
			setVisible(false);
		}
	};
	const isBrowser = typeof window !== `undefined`;
	if (isBrowser) {
		window.addEventListener("scroll", toggleVisible);
	}

	const handleClickTop = () => {
		const isBrowser = typeof window !== `undefined`;
		if (isBrowser) {
			scrollTo(0, 0);
		}
		// document.body.scrollTop = 0; /* For Safari */
		// document.documentElement.scrollTop = 0; /* For Chrome, Firefox, IE and Opera */
	};

	/* Share Modal */

	const handleShareModalOpen = () => {
		setShareModal(true);
	};

	const handleShareModalClose = () => {
		setShareModal(false);
	};

	/* Feedback Modal */

	// const [FeedbackModal, setFeedbackModal] = React.useState(false);

	// const handleFeedbackModalOpen = () => {
	//   setFeedbackModal(true);
	// };

	// const handleFeedbackModalClose = (event: any, reason: any) => {
	//   if (reason !== "backdropClick") {
	//     setFeedbackModal(false);
	//   }
	// };

	// const [screenshot, setScreenshot] = React.useState<any>();

	// const [screenshotBuffer, setScreenshotBuffer] = React.useState<any>();

	// const capture = async () => {
	//   const video = document.createElement("video");
	//   try {
	//     // asking for permission
	//     const stream = await navigator.mediaDevices.getDisplayMedia({
	//       //@ts-ignore
	//       preferCurrentTab: true,
	//     });

	//     video.addEventListener("loadedmetadata", () => {
	//       const canvas = document.createElement("canvas");

	//       const context: CanvasRenderingContext2D | null =
	//         canvas.getContext("2d") || null;
	//       //passing the video width and height to the canvas
	//       canvas.width = video.videoWidth;
	//       canvas.height = video.videoHeight;
	//       video.play();
	//       // drawing an image form the captured video stream
	//       (context as CanvasRenderingContext2D).drawImage(
	//         video,
	//         0,
	//         0,
	//         canvas.width,
	//         canvas.height
	//       );
	//       stream.getVideoTracks()[0].stop();
	//       setScreenshot(canvas.toDataURL("image/png"));
	//       try {
	//         canvas.toBlob(async (blob: Blob) => {
	//           setScreenshotBuffer(await blob.arrayBuffer());
	//         });
	//       } catch (err) {
	//         console.error("Error: " + err);
	//       }
	//     });
	//     video.srcObject = stream;
	//   } catch (err) {
	//     console.error("Error: " + err);
	//     setFeedbackModal(false);
	//   }
	// };

	return (
		<Container>
			{/*Feedback modal */}

			{/* <FeedBackModal
        session={session}
        dispatch={dispatch}
        open={FeedbackModal}
        handleClose={handleFeedbackModalClose}
        screenshotURL={screenshot}
        screenshotBuffer={screenshotBuffer}
      /> */}

			{/* Share Modal */}

			<ShareModal
				open={shareModal}
				handleClose={handleShareModalClose}
				url={shareUrl ? shareUrl : ""}
				session={session}
				dispatch={dispatch}
			/>

			{/* Go to top */}

			<Tooltip
				title="Scroll To Top"
				placement="left"
			>
				<CustomFab
					size="small"
					sx={{ display: visible ? "inline" : "none" }}
					aria-label="Go to top"
					onClick={handleClickTop}
				>
					<KeyboardArrowUp
						fontSize="medium"
						sx={{ marginTop: "0.3rem" }}
					/>
				</CustomFab>
			</Tooltip>

			{/* Share  */}

			{enableShare ? (
				<Tooltip
					title="Share"
					placement="left"
				>
					<CustomFab
						size="small"
						sx={{
							marginTop: "0.5rem",
							zIndex: 6,
						}}
						aria-label="Share"
						onClick={handleShareModalOpen}
					>
						<Share
							fontSize="medium"
							sx={{ padding: "0rem 0.1rem 0rem 0rem" }}
						/>
					</CustomFab>
				</Tooltip>
			) : null}

			{/* Feedback */}

			{/* <Tooltip title="Report a Bug!" placement="left">
        <CustomFab
          size="small"
          sx={{ marginTop: "0.5rem", zIndex: 6 }}
          aria-label="Share"
          onClick={() => {
            capture();
          }}
          onTouchEnd={() => {
            handleFeedbackModalOpen();
          }}
        >
          <BugReport
            fontSize="medium"
            sx={{ padding: "0rem 0.1rem 0rem 0rem" }}
          />
        </CustomFab>
      </Tooltip> */}
		</Container>
	);
};
export default Fabs;
